import { computed, inject, Injector, runInInjectionContext } from '@angular/core';
import { patchState, signalStoreFeature, type, withMethods, withState } from '@ngrx/signals';
import { entityConfig, removeAllEntities, SelectEntityId, setAllEntities, updateEntity, withEntities } from '@ngrx/signals/entities';
import { tap } from 'rxjs';
import { CachedEntity } from './CachedEntity';
import { EntityRecord } from './EntityRecord';
import { WithCachedEntityStoreConfigLoadAll } from './WithCachedEntityStoreConfigLoadAll';

export function withCachedEntityCollectionStore<
  TEntity extends object,
  SelectId extends SelectEntityId<TEntity>,
  Tid extends string | number = ReturnType<SelectId>,
>(config: WithCachedEntityStoreConfigLoadAll<TEntity, Tid>) {
  const entityCfg = entityConfig({
    entity: type<CachedEntity<TEntity, Tid>>() as any,
    selectId: (e) => e.id,
    collection: '_entities',
  });
  return signalStoreFeature(
    withState({
      _allEntity: {
        id: 'all',
        lastUpdated: 0,
        data: undefined,
        request: undefined,
        requestError: undefined,
      } as CachedEntity<TEntity[], Tid>,
    }),
    withEntities(entityCfg),
    withMethods((store) => {
      const injector = inject(Injector);

      const hasCacheEntry = (id: Tid) => {
        return store._entitiesIds().includes(id);
      };

      const isCacheValid = (id: Tid) => {
        return hasCacheEntry(id) && store._entitiesEntityMap()[id]?.lastUpdated + (config.cacheSeconds ?? 300) > Date.now();
      };
      const isCacheValidAll = () => {
        return store._allEntity()?.lastUpdated + (config.cacheSeconds ?? 300) > Date.now();
      };

      const setData = (data: TEntity) => {
        patchState(
          store,
          updateEntity(
            {
              id: config.selectId(data),
              changes: {
                lastUpdated: Date.now(),
                data,
                request: undefined,
                requestError: undefined,
              },
            },
            entityCfg,
          ),
        );
      };
      const getComputedAll = () => {
        return runInInjectionContext(injector, () => {
          const allEntity = store._allEntity();
          if (!isCacheValidAll()) {
            allEntity.requestError = undefined;
            allEntity.request = config.loadAll().pipe(
              tap({
                next(values) {
                  patchState(store, {
                    _allEntity: {
                      id: undefined as unknown as Tid,
                      lastUpdated: Date.now(),
                      data: values,
                      requestError: undefined,
                    },
                  });
                  const newEntities = values.map((value) => ({
                    id: config.selectId(value),
                    lastUpdated: 0,
                    data: value,
                    request: allEntity.request,
                    requestError: undefined,
                  }));
                  patchState(store, setAllEntities(newEntities, entityCfg));
                },
                error(err) {
                  patchState(store, {
                    _allEntity: {
                      id: undefined as unknown as Tid,
                      lastUpdated: Date.now(),
                      data: undefined,
                      request: allEntity.request,
                      requestError: err,
                    },
                  });
                  patchState(store, removeAllEntities(entityCfg));
                },
              }),
            );
            allEntity.request.subscribe();
          }
          return computed(() => {
            return {
              entity: allEntity.data,
              loading: !!allEntity.request,
              error: allEntity.requestError,
            } as EntityRecord<TEntity[]>;
          });
        });
      };
      const getComputed = (id: Tid) => {
        return computed(() => {
          getComputedAll()();
          const all = store._allEntity();
          let record = store._entitiesEntityMap()[id];
          if (!record) {
            record = {
              ...all,
              data: undefined,
            };
          }
          return {
            entity: record.data,
            loading: !!all.request,
            error: all.requestError,
          } as EntityRecord<TEntity>;
        });
      };

      return {
        get: getComputed,
        getAll: getComputedAll,
        hasCacheEntry,
        isCacheValid,
        setData,
      };
    }),
  );
}
