import { computed, inject, Injector, runInInjectionContext, Signal, untracked } from '@angular/core';
import { EmptyFeatureResult, patchState, SignalStoreFeature, signalStoreFeature, withMethods, withProps, withState } from '@ngrx/signals';
import { SelectEntityId } from '@ngrx/signals/entities';

export type WithSelectableEntityStoreConfig<TEntity, Tid extends string | number> = {
  selectEntityById(id: Tid): Signal<TEntity> | undefined;
  protected?: boolean;
};

export interface WithProtectedSelectableEntityStoreConfig<TEntity, Tid extends string | number>
  extends WithSelectableEntityStoreConfig<TEntity, Tid> {
  protected: true;
}

type WithSelectableEntityStoreSelectByIdMethod<TName extends string, TId extends string | number> = {
  [K in `set${TName}Id`]: (id: TId) => void;
};
type WithSelectableEntityStoreProtectedSelectByIdMethod<TName extends string, TId extends string | number> = {
  [K in `_set${TName}Id`]: (id: TId) => void;
};

type WithSelectableEntityStoreSelectedIdProperty<TName extends string, TId extends string | number> = {
  [K in `selected${TName}Id`]: TId | undefined;
};

type WithSelectableEntityStoreProtectedSelectedIdProperty<TName extends string, TId extends string | number> = {
  [K in `_selected${TName}Id`]: TId | undefined;
};

type WithSelectableEntityStoreSelectedProperty<TName extends string, TEntity> = {
  [K in `selected${TName}`]: Signal<TEntity | undefined>;
};

type WithSelectableEntityStoreProtectedSelectedProperty<TName extends string, TEntity> = {
  [K in `_selected${TName}`]: Signal<TEntity | undefined>;
};

export function withSelectableEntityStore<
  TName extends string,
  TEntity,
  SelectId extends SelectEntityId<TEntity>,
  TId extends string | number = ReturnType<SelectId>,
>(
  name: TName,
  config: WithProtectedSelectableEntityStoreConfig<TEntity, TId>,
): SignalStoreFeature<
  EmptyFeatureResult,
  {
    state: WithSelectableEntityStoreProtectedSelectedIdProperty<TName, TId>;
    props: WithSelectableEntityStoreProtectedSelectedProperty<TName, TEntity>;
    methods: WithSelectableEntityStoreProtectedSelectByIdMethod<TName, TId>;
  }
>;

export function withSelectableEntityStore<
  TName extends string,
  TEntity,
  SelectId extends SelectEntityId<TEntity>,
  TId extends string | number = ReturnType<SelectId>,
>(
  name: TName,
  config: WithSelectableEntityStoreConfig<TEntity, TId>,
): SignalStoreFeature<
  EmptyFeatureResult,
  {
    state: WithSelectableEntityStoreSelectedIdProperty<TName, TId>;
    props: WithSelectableEntityStoreSelectedProperty<TName, TEntity>;
    methods: WithSelectableEntityStoreSelectByIdMethod<TName, TId>;
  }
>;

export function withSelectableEntityStore<
  TName extends string,
  TEntity,
  SelectId extends SelectEntityId<TEntity>,
  TId extends string | number = ReturnType<SelectId>,
>(
  name: TName,
  config: WithSelectableEntityStoreConfig<TEntity, TId>,
): SignalStoreFeature<
  EmptyFeatureResult,
  {
    state: WithSelectableEntityStoreSelectedIdProperty<TName, TId> | WithSelectableEntityStoreProtectedSelectedIdProperty<TName, TId>;
    props: WithSelectableEntityStoreSelectedProperty<TName, TEntity> | WithSelectableEntityStoreProtectedSelectedProperty<TName, TEntity>;
    methods: WithSelectableEntityStoreSelectByIdMethod<TName, TId> | WithSelectableEntityStoreProtectedSelectByIdMethod<TName, TId>;
  }
> {
  const prot = config.protected ? '_' : '';
  const selectedIdProperty = `${prot}selected${name}Id`;
  const selectedProperty = `${prot}selected${name}`;
  const setMethod = `${prot}set${name}Id`;

  return signalStoreFeature(
    withState({
      [selectedIdProperty]: undefined,
    } as WithSelectableEntityStoreSelectedIdProperty<TName, TId> | WithSelectableEntityStoreProtectedSelectedIdProperty<TName, TId>),
    withMethods((store) => {
      return {
        [setMethod]: (id: TId) =>
          patchState(store, {
            [selectedIdProperty]: id,
          } as any),
      } as WithSelectableEntityStoreSelectByIdMethod<TName, TId> | WithSelectableEntityStoreProtectedSelectByIdMethod<TName, TId>;
    }),
    withProps((store) => {
      const injector = inject(Injector);
      const selectedSignal = computed(() => {
        const selectedId = (store as any)[selectedIdProperty]() as TId | undefined;
        if (selectedId === undefined) {
          return undefined;
        }
        return runInInjectionContext(injector, () => {
          const entitySignal = untracked(() => config.selectEntityById(selectedId));
          return entitySignal;
        });
      });

      const selected = computed(() => selectedSignal()?.() as TEntity | undefined);
      return {
        [selectedProperty]: selected,
      } as WithSelectableEntityStoreSelectedProperty<TName, TEntity> | WithSelectableEntityStoreProtectedSelectedProperty<TName, TEntity>;
    }),
  );
}
