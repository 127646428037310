import { computed, inject, Injector, numberAttribute, runInInjectionContext } from '@angular/core';
import { patchState, signalStoreFeature, type, withMethods } from '@ngrx/signals';
import { entityConfig, SelectEntityId, setEntity, updateEntity, withEntities } from '@ngrx/signals/entities';
import { tap } from 'rxjs';
import { EntityRecord } from './EntityRecord';
import { WithCachedEntityStoreConfigLoadById } from './WithCachedEntityStoreConfigLoadById';
import { CachedEntity } from './CachedEntity';

export function withCachedEntityStore<
  TEntity extends object,
  SelectId extends SelectEntityId<TEntity>,
  Tid extends string | number = ReturnType<SelectId>,
>(config: WithCachedEntityStoreConfigLoadById<TEntity, Tid>) {
  const entityCfg = entityConfig({
    entity: type<CachedEntity<TEntity, Tid>>() as any,
    selectId: (e) => e.id,
    collection: '_entities',
  });
  return signalStoreFeature(
    withEntities(entityCfg),
    withMethods((store) => {
      const injector = inject(Injector);

      const hasCacheEntry = (id: Tid) => {
        store._entitiesEntityMap();
        return store._entitiesIds().includes(id);
      };

      const isCacheValid = (id: Tid) => {
        if (!hasCacheEntry(id)) {
          return false;
        }
        const now = Date.now();
        const lastUpdated = store._entitiesEntityMap()[id]?.lastUpdated ?? Number.MIN_SAFE_INTEGER;
        const expiry = lastUpdated + (config.cacheSeconds ?? 300) * 1000;

        return expiry >= now;
      };

      const setData = (data: TEntity) => {
        patchState(
          store,
          updateEntity(
            {
              id: config.selectId(data),
              changes: {
                lastUpdated: Date.now(),
                data,
                request: undefined,
                requestError: undefined,
              },
            },
            entityCfg,
          ),
        );
      };

      const getComputed = (id: Tid) => {
        return runInInjectionContext(injector, () => {
          const entities = store._entitiesEntityMap();
          let entity = entities[id];
          if (!isCacheValid(id)) {
            if (!entity) {
              entity = {
                id,
                lastUpdated: 0,
                data: undefined,
                request: undefined,
                requestError: undefined,
              };
            }
            entity.requestError = undefined;
            entity.request = config.load!(id).pipe(
              tap({
                next(values) {
                  entity.request = undefined;
                  entity.lastUpdated = Date.now();
                  entity.data = values;
                  entity.requestError = undefined;
                  patchState(store, setEntity(entity, entityCfg));
                },
                error(err) {
                  entity.request = undefined;
                  entity.lastUpdated = Date.now();
                  entity.requestError = err;
                  patchState(store, setEntity(entity, entityCfg));
                },
              }),
            );
            patchState(store, setEntity(entity, entityCfg));
          }
          entity.request?.subscribe();
          return computed(() => {
            const e = store._entitiesEntityMap()[id];
            return {
              entity: e?.data,
              loading: !!e?.request,
              error: e?.requestError,
            } as EntityRecord<TEntity>;
          });
        });
      };

      return {
        get: getComputed,
        hasCacheEntry,
        isCacheValid,
        setData,
      };
    }),
  );
}
